import { List, ListItemButton, ListItemText, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ContentCenter } from "../UI";

const StyledSidebar = styled.div`
  border-left: solid 1px #e6e6e6;
  padding: 20px 0;
`;

const SidebarContent = styled(ContentCenter)`
  padding: 20px;
`;

const SidebarListItemButton = styled(ListItemButton)`
  font-weight: bold;
  color: #000 !important;
`;

const SidebarListItemText = styled(ListItemText)`
  && span {
    font-weight: ${props => (props.selected ? "bold" : "normal")} !important;
  }
`;

export default function Sidebar(props) {
  return (
    <StyledSidebar>
      {props.header && props.header}
      {props.avatar && (
        <SidebarContent>
          <div>
            <ContentCenter>{props.avatar}</ContentCenter>
            {props.children}
          </div>
        </SidebarContent>
      )}
      <List>
        {props.loadingMenu && (
          <>
            <SidebarListItemButton>
              <Skeleton variant="rectangular" width="100%" height={24} />
            </SidebarListItemButton>
            <SidebarListItemButton>
              <Skeleton variant="rectangular" width="100%" height={24} />
            </SidebarListItemButton>
            <SidebarListItemButton>
              <Skeleton variant="rectangular" width="100%" height={24} />
            </SidebarListItemButton>
          </>
        )}
        {props.menu &&
          props.menu.map(menuItem => (
            <SidebarListItemButton key={menuItem.label} component={Link} to={menuItem.link}>
              <SidebarListItemText
                selected={menuItem.selected}
                primary={
                  <span>
                    {menuItem.icon} {menuItem.label}
                  </span>
                }
              />
            </SidebarListItemButton>
          ))}
      </List>
    </StyledSidebar>
  );
}
