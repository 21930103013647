import React from "react";
import styled from "styled-components";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

const FooterBox = styled.footer`
  margin-top: 20px;
  padding: 150px 20px 10px 20px;
  background: #61bce5;
  background: linear-gradient(180deg, rgba(248, 248, 253, 1) 0%, rgba(97, 188, 229, 1) 60%);
`;

const Copyright = styled.div`
  text-align: center;
  line-height: 1.5rem;
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
`;

const Social = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  & a {
    margin-left: 5px;
    font-size: 2rem;
    width: 60px;
    height: 60px;
    color: #fff;
  }
`;

const FooterMenu = styled.div`
  & a {
    display: inline-block;
    margin: 0 5px;
    padding: 10px;
    color: #fff;
    text-decoration: none;
  }

  & a:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const year = new Date().getFullYear();

export default function Footer() {
  return (
    <FooterBox>
      <FooterMenu>
        <Link to="/how-it-works">How it works?</Link>
        <Link to="/about-us">About Us</Link>
        <Link to="/need-help">Help</Link>
      </FooterMenu>
      <Social>
        <IconButton href="https://twitter.com/JesusWithMeNow" target="_blank" rel="external">
          <FontAwesomeIcon icon={faTwitter} />
        </IconButton>
      </Social>
      <Copyright>Copyright © {year} Jesus with me. Made with ❤️ in California.</Copyright>
    </FooterBox>
  );
}
