import React, { useEffect, useRef, useState } from "react";
import DefaultPage from "../component/DefaultPage";
import { ButtonBar, Form, PageTitle, SmallIcon, Title } from "../component/UI";
import { Button, Container, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { faAt, faCheck, faChevronRight, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import TextVerification from "../component/TextVerification";
import { validateEmail } from "../validation/Validation";
import UserApi from "../api/UserApi";
import { displayMessage } from "../event/Actions";
import { Dispatcher } from "@aux4/dispatcher.js";
import { MESSAGE } from "../event/Events";
import { storeCredentials } from "../api/SecurityApi";
import { LOGIN } from "../event/SecurityEvents";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { normalizeUsername } from "../text/Normalizer";
import { setTitle } from "../util/Utils";
import { Helmet } from "react-helmet";

export default function SignInPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState(0);
  const [type, setType] = useState("username");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [user, setUser] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [canSendAnotherCode, setCanSendAnotherCode] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const usernameRef = useRef();
  const emailRef = useRef();

  useEffect(() => {
    usernameRef.current.focus();
    setTitle("Sign In");
  }, []);

  useEffect(() => {
    if (countdown <= 0) return;

    setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);
  }, [countdown]);

  useEffect(() => {
    const { email } = location.state || {};
    if (email) {
      setType("email");
      setEmail(email);
    }
  }, [location]);

  const handleChangeType = event => {
    const newType = event.target.value;
    setType(newType);

    setTimeout(() => {
      if (newType === "username") {
        usernameRef.current.focus();
      } else {
        emailRef.current.focus();
      }
    }, 10);
  };

  const handleChange = event => {
    if (type === "username") {
      setUsername(event.target.value);
    } else {
      setEmail(event.target.value);
    }
  };

  const handleInputEmail = event => {
    event.target.value = event.target.value.toLowerCase();
  };

  const handleTextVerificationChange = text => {
    setVerificationCode(text);
  };

  const enableResendAfterInterval = () => {
    setCountdown(29);

    setTimeout(() => {
      setCanSendAnotherCode(true);
    }, 30_000);
  };

  const nextStep = async () => {
    let user;
    if (type === "username") {
      user = await UserApi.findByUsername(username);
    } else {
      user = await UserApi.findByEmail(email);
    }

    if (user) {
      setUser(user);
      await requestEmailVerification(user);
      setStep(1);
    } else {
      displayMessage({ text: "User not found", variant: "error" });
    }
  };

  const requestEmailVerification = async currentUser => {
    setCanSendAnotherCode(false);
    enableResendAfterInterval();

    try {
      await UserApi.emailVerification(currentUser.id, email, currentUser.username);
    } catch (e) {
      console.error("Error requesting email verification user", e);
      Dispatcher.dispatch(MESSAGE, { text: "Error sending email: " + e.message, variant: "error" });
    }
  };

  const verifyEmail = async () => {
    try {
      const session = await UserApi.verifyEmail(user.id, verificationCode);
      storeCredentials({ id: session.id, expires: session.expires, user: user });
      Dispatcher.dispatch(LOGIN);
      navigate("/");
    } catch (e) {
      Dispatcher.dispatch(MESSAGE, { text: "Cannot verify email: " + e.message, variant: "error" });
    }
  };

  const handleUsernameInput = e => {
    e.target.value = normalizeUsername(e.target.value);
  };

  return (
    <DefaultPage>
      <Helmet>
        <meta
          name="description"
          content="Sign in to Jesus with me, a platform where people can share their experiences of living with Jesus Christ."
        />
        <meta property="twitter.title" content="Sign In · Jesus with me" />
        <meta
          name="twitter.description"
          content="Sign in to Jesus with me, a platform where people can share their experiences of living with Jesus Christ."
        />
        <meta property="og:title" content="Sign In · Jesus with me" />
        <meta
          name="og:description"
          content="Sign in to Jesus with me, a platform where people can share their experiences of living with Jesus Christ."
        />
      </Helmet>
      <Container>
        <PageTitle>Welcome back!</PageTitle>
        <Grid container>
          <Grid item xs={12} md={6} lg={4}>
            {step === 0 && (
              <Form>
                <RadioGroup row value={type} onChange={handleChangeType}>
                  <FormControlLabel value="username" control={<Radio />} label="Username" />
                  <FormControlLabel value="email" control={<Radio />} label="Email" />
                </RadioGroup>
                {type === "username" && (
                  <TextField
                    fullWidth
                    label="Username"
                    placeholder="Enter your username"
                    value={username}
                    inputRef={usernameRef}
                    onInput={handleUsernameInput}
                    onChange={handleChange}
                    InputProps={{ startAdornment: <SmallIcon icon={faAt} /> }}
                  />
                )}
                {type === "email" && (
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    inputRef={emailRef}
                    onChange={handleChange}
                    onInput={handleInputEmail}
                    InputProps={{ startAdornment: <SmallIcon style={{ marginRight: "5px" }} icon={faEnvelope} /> }}
                  />
                )}
                <ButtonBar>
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<SmallIcon icon={faChevronRight} />}
                    onClick={nextStep}
                    disabled={(type === "username" && username === "") || (type === "email" && !validateEmail(email))}>
                    Next
                  </Button>
                </ButtonBar>
                <p>
                  If you don't have an account you can{" "}
                  <Button component={Link} to="/signup">
                    Sign Up
                  </Button>
                </p>
              </Form>
            )}
            {step === 1 && (
              <Form>
                <Title>Verify your email</Title>
                <p>Please verify your email and enter the verification code:</p>
                <TextVerification onChange={handleTextVerificationChange} chars={6} />
                <ButtonBar>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!canSendAnotherCode}
                    onClick={() => requestEmailVerification(user)}>
                    Send again {countdown === 0 ? "" : `(${countdown})`}
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={verifyEmail}
                    startIcon={<SmallIcon icon={faCheck} />}
                    disabled={verificationCode.length !== 6}>
                    Verify
                  </Button>
                </ButtonBar>
              </Form>
            )}
          </Grid>
        </Grid>
      </Container>
    </DefaultPage>
  );
}
