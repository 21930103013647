import React, { useEffect, useState } from "react";
import UserApi from "../api/UserApi";
import { Dispatcher } from "@aux4/dispatcher.js";
import { MESSAGE } from "../event/Events";
import { Button, Container, Step, StepLabel, Stepper } from "@mui/material";
import SignUpForm from "../component/signUp/SignUpForm";
import Terms from "../component/signUp/Terms";
import EmailVerification from "../component/signUp/EmailVerification";
import { ButtonBar, PageTitle, SmallIcon } from "../component/UI";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import DefaultPage from "../component/DefaultPage";
import { useNavigate } from "react-router-dom";
import { storeCredentials } from "../api/SecurityApi";
import { LOGIN } from "../event/SecurityEvents";
import { setTitle } from "../util/Utils";
import { Helmet } from "react-helmet";

export default function SignUpPage() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [validSteps, setValidSteps] = useState([false, false, false]);
  const [userForm, setUserForm] = useState(null);
  const [terms, setTerms] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [canSendAnotherCode, setCanSendAnotherCode] = useState(false);
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    setTitle("Sign Up");
  }, []);

  const nextStep = async () => {
    if (activeStep === 0 && userForm.id === undefined) {
      const created = await createUser();
      if (created) {
        setActiveStep(activeStep + 1);
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const previousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleUserFormChange = form => {
    setUserForm(form);
  };

  const handleTermsChange = terms => {
    setTerms(terms);
  };

  const handleTextVerificationChange = text => {
    setVerificationCode(text);
  };

  const handleFormValidation = (step, valid) => {
    const newValidSteps = [...validSteps];
    newValidSteps[step] = valid;
    setValidSteps(newValidSteps);
  };

  const enableResendAfterInterval = () => {
    setCountdown(29);

    setTimeout(() => {
      setCanSendAnotherCode(true);
    }, 30_000);
  };

  const createUser = async () => {
    let user;

    try {
      user = await UserApi.createUser(userForm);
      setUserForm(user);
    } catch (e) {
      console.error("Error creating user", e);
      Dispatcher.dispatch(MESSAGE, { text: "Error creating user: " + e.message, variant: "error" });
      return false;
    }

    setTimeout(async () => {
      await requestEmailVerification(user);
    }, 100);

    return true;
  };

  const requestEmailVerification = async user => {
    setCanSendAnotherCode(false);
    enableResendAfterInterval();

    try {
      await UserApi.emailVerification(user.id, user.email);
    } catch (e) {
      Dispatcher.dispatch(MESSAGE, { text: "Error sending email: " + e.message, variant: "error" });
    }
  };

  const verifyEmail = async () => {
    try {
      const session = await UserApi.verifyEmail(userForm.id, verificationCode);
      storeCredentials({ id: session.id, expires: session.expires, user: userForm });
      Dispatcher.dispatch(LOGIN);
      navigate("/");
    } catch (e) {
      Dispatcher.dispatch(MESSAGE, { text: "Cannot verify email: " + e.message, variant: "error" });
    }
  };

  useEffect(() => {
    if (countdown <= 0) return;

    setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);
  }, [countdown]);

  return (
    <DefaultPage>
      <Helmet>
        <meta
          name="description"
          content="Sign up to Jesus with me, a platform where people can share their experiences of living with Jesus Christ."
        />
        <meta property="twitter.title" content="Sign Up · Jesus with me" />
        <meta
          name="twitter.description"
          content="Sign up to Jesus with me, a platform where people can share their experiences of living with Jesus Christ."
        />
        <meta property="og:title" content="Sign Up · Jesus with me" />
        <meta
          name="og:description"
          content="Sign up to Jesus with me, a platform where people can share their experiences of living with Jesus Christ."
        />
      </Helmet>
      <Container>
        <PageTitle>Create your account</PageTitle>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>You</StepLabel>
          </Step>
          <Step>
            <StepLabel>Terms</StepLabel>
          </Step>
          <Step>
            <StepLabel>Confirmation</StepLabel>
          </Step>
        </Stepper>
        {activeStep === 0 && (
          <SignUpForm
            value={userForm}
            ignoreFields={["title"]}
            onChange={handleUserFormChange}
            onValidate={valid => handleFormValidation(0, valid)}
          />
        )}
        {activeStep === 1 && (
          <Terms value={terms} onChange={handleTermsChange} onValidate={valid => handleFormValidation(1, valid)} />
        )}
        {activeStep === 2 && (
          <EmailVerification
            email={userForm.email}
            onChange={handleTextVerificationChange}
            onValidate={valid => handleFormValidation(2, valid)}
          />
        )}
        <ButtonBar>
          {activeStep > 0 && (
            <Button color="secondary" onClick={previousStep} startIcon={<SmallIcon icon={faChevronLeft} />}>
              Back
            </Button>
          )}
          {activeStep !== 2 && (
            <Button variant="contained" color="primary" onClick={nextStep} disabled={!validSteps[activeStep]}>
              Next
            </Button>
          )}
          {activeStep === 2 && (
            <>
              <Button
                variant="contained"
                color="secondary"
                disabled={!canSendAnotherCode}
                onClick={() => requestEmailVerification(userForm)}>
                Send again {countdown === 0 ? "" : `(${countdown})`}
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={verifyEmail}
                startIcon={<SmallIcon icon={faCheck} />}
                disabled={!validSteps[2]}>
                Verify
              </Button>
            </>
          )}
        </ButtonBar>
      </Container>
    </DefaultPage>
  );
}
