import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PostApi from "../api/PostApi";
import DefaultPage from "../component/DefaultPage";
import EditPost from "../component/post/EditPost";
import Post from "../component/post/Post";
import AuthorSidebar from "../component/sidebar/AuthorSidebar";
import { setTitle } from "../util/Utils";

const PageContent = styled.div``;

export default function PostPage() {
  const { postId } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    PostApi.findById(postId).then(post => {
      setPost(post);
      setTitle(`${post.title || "Post"} by ${post.user.name}`);
    });
  }, [postId]);

  return (
    <DefaultPage>
      {post && (
        <Helmet>
          <meta name="description" content={post.short} />
          <meta property="twitter.title" content={`${post.title || "Post"} by ${post.user.name} · Jesus with me`} />
          <meta name="twitter.description" content={post.short} />
          <meta property="og:title" content={`${post.title || "Post"} by ${post.user.name} · Jesus with me`} />
          <meta name="og:description" content={post.short} />
        </Helmet>
      )}
      <Grid container>
        <Grid item xs={12} sm={3} md={2}>
          <AuthorSidebar user={(post || {}).user} />
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          {post && (
            <PageContent>
              {post.edit && <EditPost expanded content={post} />}
              {!post.edit && <Post expanded content={post} />}
            </PageContent>
          )}
        </Grid>
      </Grid>
    </DefaultPage>
  );
}
