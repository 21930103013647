import { CardHeader, Skeleton, Tooltip } from "@mui/material";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AuthorAvatar from "../avatar/AuthorAvatar";
import { AuthorName, AuthorUsername, PostTime } from "../UI";

const StyledCardHeader = styled(CardHeader)`
  & a {
    text-decoration: none;
    color: inherit !important;
  }
`;

export default function PostHeader(props) {
  return (
    <StyledCardHeader
      avatar={<AuthorAvatar user={props.post && props.post.user} />}
      action={props.headerAction}
      title={
        <div>
          <PostAuthor post={props.post} />
          <PostAuthorUsername post={props.post} />
          <PostCreatedAt post={props.post} />
          <PostEditedTag post={props.post} />
        </div>
      }
      subheader={props.post && props.post.user.title}
    />
  );
}

function PostAuthor(props) {
  if (!props.post) {
    return (
      <AuthorName>
        <Skeleton variant="rectangular" width="100%" height={10} />
      </AuthorName>
    );
  }

  return (
    <AuthorName>
      <Link to={`/${props.post.user.username}`}>{props.post.user.name}</Link>
    </AuthorName>
  );
}

function PostAuthorUsername(props) {
  if (!props.post) {
    return (
      <AuthorUsername>
        <Skeleton variant="rectangular" width="100%" height={10} />
      </AuthorUsername>
    );
  }

  return (
    <AuthorUsername>
      <Link to={`/${props.post.user.username}`}>@{props.post.user.username}</Link>
    </AuthorUsername>
  );
}

function PostCreatedAt(props) {
  if (!props.post) {
    return (
      <PostTime>
        <Skeleton variant="rectangular" width="100%" height={10} />
      </PostTime>
    );
  }

  if (!props.post.createdAt) {
    return null;
  }

  return (
    <>
      {" · "}
      <Tooltip title={moment.unix(props.post.createdAt).format("hh:mm A · MMM DD, YYYY")}>
        <PostTime>
          <Link to={`/posts/${props.post.id}`}>{moment.unix(props.post.createdAt).fromNow()}</Link>
        </PostTime>
      </Tooltip>
    </>
  );
}

function PostEditedTag(props) {
  if (!props.post) {
    return (
      <PostTime>
        <Skeleton variant="rectangular" width="100%" height={10} />
      </PostTime>
    );
  }

  if (!props.post.updatedAt || props.post.createdAt === props.post.updatedAt) {
    return null;
  }

  return (
    <>
      {" · "}
      <Tooltip title={moment.unix(props.post.updatedAt).format("hh:mm A · MMM DD, YYYY")}>
        <PostTime>edited</PostTime>
      </Tooltip>
    </>
  );
}
