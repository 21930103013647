import { useHandler } from "@aux4/use-handler";
import { MESSAGE } from "../event/Events";
import { useSnackbar } from "notistack";

export default function Messages() {
  const { enqueueSnackbar } = useSnackbar();

  useHandler(
    (eventType, message) => {
      enqueueSnackbar(message.text, { variant: message.variant });
    },
    [MESSAGE]
  );

  return null;
}
