import React, { useEffect } from "react";
import { Page } from "./UI";
import Header from "./Header";
import Footer from "./Footer";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

export default function DefaultPage(props) {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.send("pageview");
    }
  }, [location]);

  return (
    <Page>
      <Header />
      {props.children}
      <Footer />
    </Page>
  );
}
