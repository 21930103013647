import { faDove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import styled from "styled-components";
import { openPostDialog } from "../../../event/PostActions";

const TestimonyStyledButton = styled(Button)`
  && {
    margin-bottom: 10px;
  }
`;

export default function TestimonyButton(props) {
  return (
    <TestimonyStyledButton
      variant="contained"
      color="success"
      size="small"
      startIcon={<FontAwesomeIcon icon={faDove} />}
      onClick={() =>
        openPostDialog({
          text: `Your story starts here...\n\n#testimony`,
          references: [
            {
              type: "LINK",
              reference: "pray",
              id: props.postId
            }
          ]
        })
      }>
      Write your Testimony
    </TestimonyStyledButton>
  );
}
