import { NakedLink, PageTitle } from "../component/UI";
import React, { useEffect } from "react";
import DefaultPage from "../component/DefaultPage";
import { Container } from "@mui/material";
import { setTitle } from "../util/Utils";

export default function HelpPage() {
  useEffect(() => {
    setTitle("Do you need help?");
  }, []);

  return (
    <DefaultPage>
      <Container>
        <PageTitle>Do you need help?</PageTitle>
        <p>
          If you are looking to find out how our website works, the information you are looking for might be available
          on our <NakedLink to="/how-it-works">how it works</NakedLink> page.
        </p>
        <p>
          If you are looking to find out about this website, who made and why we made it check out our{" "}
          <NakedLink to="/about-us">about us</NakedLink> page.
        </p>
        <h2>Questions?</h2>
        <p>
          If you have a question, suggestion or need support please feel free to write a post and tag{" "}
          <NakedLink to="/help">@help</NakedLink>. Our team is going to do the best to answer your question as soons as
          possible.
        </p>
      </Container>
    </DefaultPage>
  );
}
