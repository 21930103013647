import { Dispatcher } from "@aux4/dispatcher.js";
import {
  CANCEL_EDIT_POST,
  CLOSE_POST_DIALOG,
  EDIT_POST,
  FORCE_RELOAD_COMMENTS,
  NEW_POST_CREATED,
  OPEN_POST_DIALOG,
  POST_REACTION,
  POST_REMOVED,
  RELOAD_POSTS
} from "./PostEvents";

export function newPostCreated(post) {
  Dispatcher.dispatch(NEW_POST_CREATED, post);
}

export function postRemoved(postId) {
  Dispatcher.dispatch(POST_REMOVED, postId);
}

export function editPost(postId) {
  Dispatcher.dispatch(EDIT_POST, postId);
}

export function cancelEditPost(postId) {
  Dispatcher.dispatch(CANCEL_EDIT_POST, postId);
}

export function postReaction(postId, reaction) {
  Dispatcher.dispatch(POST_REACTION, { postId, reaction });
}

export function reloadPosts() {
  Dispatcher.dispatch(RELOAD_POSTS);
}

export function forceReloadComments(postId) {
  Dispatcher.dispatch(FORCE_RELOAD_COMMENTS, postId);
}

export function openPostDialog(post) {
  Dispatcher.dispatch(OPEN_POST_DIALOG, post);
}

export function closePostDialog() {
  Dispatcher.dispatch(CLOSE_POST_DIALOG);
}
