import { Card, CardActions, CardMedia } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MarkdownContent } from "../UI";
import PostComments from "./comment/PostComments";
import PostHeader from "./PostHeader";

const Article = styled.article`
  margin: 0 auto;
  max-width: ${props => (props.expanded ? "700px" : "600px")};
  width: 100%;
`;

const PostCardActions = styled(CardActions)`
  display: flex;
  justify-content: space-between;
`;

const PostInternalCard = styled(Card)`
  && {
    border-radius: 0;
    box-shadow: none;
  }
`;

export default function PostCard(props) {
  const [content, setContent] = useState(props.content);

  useEffect(() => {
    setContent(props.content);
  }, [props.content]);

  return (
    <Article expanded={props.expanded}>
      <PostInternalCard>
        <PostHeader post={content} headerAction={props.headerAction} />
        {content && content.image && (
          <Link to={`/${content.user.username}/${content.id}`}>
            <CardMedia component="img" image={content.image} />
          </Link>
        )}
        <MarkdownContent>{props.children}</MarkdownContent>
        <PostCardActions>{props.actions}</PostCardActions>
        {content && content.id && !props.disableComments && <PostComments postId={content.id} />}
      </PostInternalCard>
    </Article>
  );
}
