import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TextField } from "@mui/material";

const Box = styled.div`
  margin: 0 auto;
  padding: 0;
  max-width: 600px;
`;

const CharField = styled(TextField).attrs(props => ({
  inputProps: {
    maxLength: 1,
    inputMode: "numeric",
    pattern: "[0-9]{1}"
  },
  variant: "outlined",
  margin: "normal",
  maxLength: 1,
  style: {
    margin: "5px"
  }
}))`
  width: 60px;
  input {
    text-align: center;
    font-size: 25px;
  }
  fieldset {
    border-width: 1px !important;
    border-color: rgba(0, 0, 0, 0.23) !important;
  }
`;

const VerificationCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DEFAULT_CHARS = 4;

export default function TextVerification(props) {
  const [text, setText] = useState("");
  const [charRefs] = useState(Array.from(Array(props.chars || DEFAULT_CHARS).keys()).map(() => React.createRef()));
  const [chars, setChars] = useState(Array.from(Array(props.chars || DEFAULT_CHARS).keys()).map(() => ""));

  useEffect(() => {
    charRefs[0].current.focus();
  }, []);

  const handleKeyDown = event => {
    if (event.key === "Backspace") {
      const updatedText = text.substring(0, text.length - 1);
      const updatedChars = updateText(updatedText);

      handleFocus(event, updatedChars);
    }
  };

  const handleKeyUp = (event, index) => {
    if (/^[0-9]$/i.test(event.key)) {
      const updatedText = setCharAt(text, index, event.key);
      const updatedChars = updateText(updatedText);

      if (index < updatedChars.length - 1) {
        setTimeout(() => {
          handleFocus(event, updatedChars);
        }, 0);
      }
    } else {
      handleFocus(event, chars);
    }
  };

  const handleFocus = (event, chars) => {
    for (let i = 0; i < chars.length; i++) {
      if (chars[i] === "") {
        charRefs[i].current.focus();
        break;
      }
    }
  };

  const handlePaste = event => {
    if (!event.clipboardData || !event.clipboardData.getData) {
      return;
    }

    const data = event.clipboardData.getData("text/plain");
    handleChange({ target: { value: data } });
  };

  const handleChange = event => {
    const data = event.target.value;
    if (data.length === (props.chars || DEFAULT_CHARS)) {
      updateText(data);
    }
  };

  const updateText = text => {
    setText(text);

    const updatedChars = Array.from(Array(props.chars || DEFAULT_CHARS).keys()).map(() => "");
    text.split("").forEach((char, index) => {
      updatedChars[index] = char;
    });

    setChars(updatedChars);

    if (props.onChange) {
      props.onChange(text);
    }

    return updatedChars;
  };

  const setCharAt = function (text, index, key) {
    return text.substring(0, index) + key + text.substring(index + 1);
  };

  return (
    <Box>
      <VerificationCode>
        {chars.map((char, index) => (
          <CharField
            value={char}
            onChange={e => handleChange(e)}
            onFocus={e => handleFocus(e, chars)}
            onKeyDown={e => handleKeyDown(e, index)}
            onKeyUp={e => handleKeyUp(e, index)}
            onPaste={e => handlePaste(e)}
            inputRef={charRefs[index]}
            autoComplete="one-time-code"
          />
        ))}
      </VerificationCode>
    </Box>
  );
}
