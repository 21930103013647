import { useNavigate } from "react-router-dom";
import { useHandler } from "@aux4/use-handler";
import { SIGNIN } from "../event/SecurityEvents";

export default function Redirector() {
  const navigate = useNavigate();

  useHandler(() => {
    navigate("/signin");
  }, [SIGNIN]);

  return null;
}
