import Api from "./Api";

const PostApi = {
  findById: async function (id) {
    const response = await Api.get(`/posts/${id}`);
    return response.data;
  },

  posts: async function () {
    const response = await Api.get("/posts");
    return response.data.posts;
  },

  postsByUser: async function (userId) {
    const response = await Api.get(`/users/${userId}/posts`);
    const data = response.data;
    return data.posts.map(post => {
      post.user = data.user;
      return post;
    });
  },

  postsByReference: async function (referenceType, reference) {
    const response = await Api.get(`/references/${referenceType}/${reference}`);
    const data = response.data;
    return data.posts;
  },

  create: async function (post) {
    const response = await Api.post(`/posts`, post);
    return response.data;
  },

  update: async function (post) {
    const response = await Api.put(`/posts/${post.id}`, post);
    return response.data;
  },

  remove: async function (id) {
    const response = await Api.delete(`/posts/${id}`);
    return response.data;
  },

  comments: async function (postId) {
    const response = await Api.get(`/posts/${postId}/comments`);
    return response.data;
  },

  comment: async function (postId, text) {
    const response = await Api.post(`/posts/${postId}/comments`, { text });
    return response.data;
  },

  deleteComment: async function (postId, commentId) {
    const response = await Api.delete(`/posts/${postId}/comments/${commentId}`);
    return response.data;
  },

  reactions: async function (postId) {
    const response = await Api.get(`/posts/${postId}/reactions`);
    return response.data;
  },

  react: async function (postId, reaction) {
    const response = await Api.post(`/posts/${postId}/react`, {
      reaction
    });
    return response.data;
  },

  unReact: async function (postId, reaction) {
    const response = await Api.delete(`/posts/${postId}/reactions/${reaction}`);
    return response.data;
  }
};

export default PostApi;
