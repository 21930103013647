import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import PostApi from "../api/PostApi";
import DefaultPage from "../component/DefaultPage";
import HomeSidebar from "../component/sidebar/HomeSidebar";
import PostLane from "../component/post/PostLane";
import { setTitle } from "../util/Utils";

export default function HomePage() {
  useEffect(() => {
    setTitle("");
  }, []);

  async function reloadPosts() {
    return await PostApi.posts();
  }

  return (
    <DefaultPage>
      <Helmet>
        <meta
          name="description"
          content="This is a platform to share how are you walking with Jesus. Get inspired by messages and testimonials and also share your story to help other people to know what Jesus can do in their lives."
        />
        <meta property="twitter.title" content="Jesus with me" />
        <meta
          name="twitter.description"
          content="This is a platform to share how are you walking with Jesus. Get inspired by messages and testimonials and also share your story to help other people to know what Jesus can do in their lives."
        />
        <meta property="og:title" content="Jesus with me" />
        <meta
          name="og:description"
          content="This is a platform to share how are you walking with Jesus. Get inspired by messages and testimonials and also share your story to help other people to know what Jesus can do in their lives."
        />
      </Helmet>
      <Grid container>
        <Grid item xs={12} sm={3} md={2}>
          <HomeSidebar />
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          <PostLane loadPosts={reloadPosts} />
        </Grid>
      </Grid>
    </DefaultPage>
  );
}
