import { faAt, faHashtag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useSession } from "../../util/Hooks";

const SuggestionItem = styled(Chip)`
  color: #33b5f0;
  cursor: pointer;

  && {
    margin-right: 5px;
  }
`;

const SuggestionIcon = styled(FontAwesomeIcon)`
  padding-left: 5px;
`;

export default function SuggestionBox(props) {
  const session = useSession();
  const { hashtag, username } = useParams();
  const [hasUsername, setHasUsername] = useState(false);
  const [hasHashtag, setHasHashtag] = useState(false);
  const [hasGod, setHasGod] = useState(false);
  const [hasJesus, setHasJesus] = useState(false);

  useEffect(() => {
    const text = props.text || "";

    const lowercaseText = text.toLowerCase();
    if (username) {
      if (session && session.user.username === username) {
        setHasUsername(true);
      } else {
        setHasUsername(lowercaseText.includes(`@${username.toLowerCase()}`));
      }
    }
    if (hashtag) {
      setHasHashtag(lowercaseText.includes(`#${hashtag.toLowerCase()}`));
    }

    setHasGod(text === "god" || text.endsWith(" god"));
    setHasJesus(text === "jesus" || text.endsWith(" jesus"));
  }, [props.text, username, session, hashtag]);

  const handleSuggestion = function (text, length) {
    let appendedText;
    if (length !== undefined || props.text === "" || props.text.endsWith(" ")) {
      appendedText = `${text} `;
    } else {
      appendedText = ` ${text}`;
    }
    props.onSuggest(appendedText, length);
  };

  return (
    <div>
      {username && !hasUsername && (
        <SuggestionItem
          label={username.toLowerCase()}
          icon={<SuggestionIcon icon={faAt} />}
          onClick={() => handleSuggestion(`@${username.toLowerCase()}`)}
        />
      )}
      {hashtag && !hasHashtag && (
        <SuggestionItem
          label={hashtag.toLowerCase()}
          icon={<SuggestionIcon icon={faHashtag} />}
          onClick={() => handleSuggestion(`#${hashtag.toLowerCase()}`)}
        />
      )}
      {hasJesus && (
        <SuggestionItem
          label={"jesus"}
          icon={<SuggestionIcon icon={faAt} />}
          onClick={() => handleSuggestion(`@jesus`, "jesus".length)}
        />
      )}
      {hasGod && (
        <SuggestionItem
          label={"god"}
          icon={<SuggestionIcon icon={faAt} />}
          onClick={() => handleSuggestion(`@god`, "god".length)}
        />
      )}
    </div>
  );
}
