import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { setTitle } from "../util/Utils";
import PostApi from "../api/PostApi";
import DefaultPage from "../component/DefaultPage";
import { Grid } from "@mui/material";
import HashtagSidebar from "../component/sidebar/HashtagSidebar";
import PostLane from "../component/post/PostLane";
import { reloadPosts } from "../event/PostActions";
import { Metadata } from "../component/Meta";

export default function HashtagPage() {
  const { hashtag } = useParams();

  async function loadPosts() {
    return await PostApi.postsByReference("hashtag", hashtag.toLowerCase());
  }

  useEffect(() => {
    setTitle(hashtag);
    reloadPosts();
  }, [hashtag]);

  return (
    <DefaultPage>
      <Metadata
        title={`${hashtag} · Jesus with me`}
        description={`See posts about #${hashtag} on Jesus with me. See people's story and share yours as well.`}
      />
      <Grid container>
        <Grid item xs={12} sm={3} md={2}>
          <HashtagSidebar hashtag={hashtag} />
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          <PostLane loadPosts={loadPosts} />
        </Grid>
      </Grid>
    </DefaultPage>
  );
}
