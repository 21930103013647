import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import PostApi from "../../api/PostApi";
import { useSession } from "../../util/Hooks";
import { filterReferenceByType, hasHashtag, hasReference, isUserPost } from "../../util/PostUtils";
import { Markdown } from "../Markdown";
import TestimonyButton from "./button/TestimonyButton";
import PostActions from "./PostActions";
import PostCard from "./PostCard";
import PostMenu from "./PostMenu";
import ReadTime from "./ReadTime";
import PostLinkReference from "./reference/PostLinkReference";
import ReferenceCarousel from "./reference/ReferenceCarousel";

export default function Post(props) {
  const session = useSession();
  const [content, setContent] = useState(props.content);
  const [expanded, setExpanded] = useState(props.expanded);

  useEffect(() => {
    setContent(props.content);
  }, [props.content]);

  const handleExpand = async expanded => {
    if (expanded) {
      const post = await PostApi.findById(props.content.id);
      setContent(post);
    }
    setExpanded(expanded);
  };

  return (
    <PostCard
      expanded={props.expanded}
      content={props.content}
      headerAction={isUserPost(session, content) && <PostMenu postId={content.id} />}
      actions={<PostActions content={content} onExpand={handleExpand} expanded={expanded} />}>
      {!content && <Skeleton variant="rectangular" width="100%" height={80} />}
      {content && content.id && (
        <>
          <Markdown text={expanded ? content.text : content.short || content.text} post={content} />
          {isUserPost(session, content) && hasHashtag(content, "pray") && <TestimonyButton postId={content.id} />}
          {hasReference(content, "URL") && <ReferenceCarousel references={content.references} />}
          {content.short !== content.text && !expanded && <ReadTime content={content} onExpand={handleExpand} />}
          {filterReferenceByType(content.references, "LINK").map(reference => (
            <PostLinkReference key={reference.id} postId={reference.id} linkType={reference.reference} />
          ))}
        </>
      )}
    </PostCard>
  );
}
