import { useEffect, useState } from "react";
import TopicApi from "../../api/TopicApi";
import { useSession } from "../../util/Hooks";
import Sidebar from "./Sidebar";
import SignUpBox from "../signUp/SignUpBox";

export default function HomeSidebar(props) {
  const session = useSession();
  const [loadingTopics, setLoadingTopics] = useState(true);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    setLoadingTopics(true);

    TopicApi.list().then(topics => {
      setTopics(topics);
      setLoadingTopics(false);
    });
  }, []);

  return (
    <Sidebar
      header={!session && <SignUpBox />}
      loadingMenu={loadingTopics}
      menu={
        topics &&
        topics.map(topic => ({
          label: `#${topic.name}`,
          link: `/hashtag/${topic.name}`,
          selected: topic.name === props.hashtag
        }))
      }
    />
  );
}
