import React, { useEffect } from "react";
import styled from "styled-components";
import DefaultPage from "../component/DefaultPage";
import { NakedLink, PageTitle, SmallIcon } from "../component/UI";
import { Button, Container } from "@mui/material";

import picDavid from "../static/img/dgouvea.jpg";
import picAndrew from "../static/img/aweeks.jpg";
import { Link } from "react-router-dom";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import { setTitle } from "../util/Utils";

const ProfilePicture = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: solid 3px #00a3ed;
  width: 100px;
  height: 100px;
`;

const TeamMemberCard = styled.div`
  position: relative;
  margin-top: 40px;
  padding-left: 120px;

  & h3 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  & h3 a {
    color: inherit;
    text-decoration: none;
  }
  & h5 {
    margin-top: 0;
    color: #00a3ed;
  }
`;

const Blockquote = styled.blockquote`
  padding-left: 60px;
  line-height: 1.5rem;
  color: #666;

  & span {
    margin-top: 5px;
    display: block;
    text-align: right;
    font-weight: bold;
    color: #333;
  }
`;

const UL = styled.ul`
  padding-left: 18px;
`;

export default function AboutUsPage() {
  useEffect(() => {
    setTitle("About Us");
  }, []);

  return (
    <DefaultPage>
      <Container>
        <PageTitle>About Us</PageTitle>
        <p>
          <strong>Jesus with me</strong> is a new social media platform where you can:
          <UL>
            <li>
              <i>Testimony</i>: share your experience with Jesus Christ and be inspired by the amazing stories of what
              Jesus has done in others' lives.
            </li>
            <li>
              <i>Pray</i>: be actively involved in a deep Christian community by supporting (and being supported by)
              others in prayer.
            </li>
            <li>
              <i>Study</i>: dive deeper into the word and bring your insights
            </li>
          </UL>
        </p>
        <h2>Who is Jesus Christ?</h2>
        <p>
          Jesus is the son of God who lived a life without sin on our behalf, who died a painful death that our sins
          deserved and forgave us. Three days after his death, he was resurrected and invited us to join him in the
          journey from death to life and life abundantly..
        </p>

        <p>
          <Blockquote>
            For God so loved the world that he gave his one and only Son, that whoever believes in him shall not perish
            but have eternal life.
            <span>John 3:16</span>
          </Blockquote>
        </p>
        <p>
          <Blockquote>
            This is how God showed his love among us: He sent his one and only Son into the world that we might live
            through him. <span>1 John 4:9</span>
          </Blockquote>
        </p>
        <p>
          <Blockquote>
            I am the way and the truth and the life. No one comes to the Father except through me. If you really know
            me, you will know my Father as well. From now on, you do know him and have seen him.
            <span>John 14:6-7</span>
          </Blockquote>
        </p>
        <p>
          <Blockquote>
            And he died for all, that those who live should no longer live for themselves but for him who died for them
            and was raised again.
            <span>2 Corinthians 5:15</span>
          </Blockquote>
        </p>
        <p>
          <Blockquote>
            Jesus said to her, "I am the resurrection and the life. The one who believes in me will live, even though
            they die; and whoever lives by believing in me will never die. Do you believe this?"
            <span>John 11:25-26</span>
          </Blockquote>
        </p>
        <h2>Our Platform</h2>
        <p>
          <strong>Jesus with me</strong> is open to everyone who wants to share their journey with Jesus. This is not a
          place to share opinions or judgments about other people, but a place where you can share <i>your</i> stories
          to inspire and encourage others who might be facing similar issues.
        </p>
        <p>
          We highly encourage you to be active on this platform, which looks like sharing your story, testimonies, and
          many examples of what God the Father, God the Son, and the Holy Spirit have done in your life. Perhaps someone
          in your family was healed, you found a great job, were promoted, met your partner, or are just encouraged by
          what God has done -- please share your story.
        </p>
        <p>
          No one's life is perfect and it is never easy to deal with the problems that life throws at us. In these
          situations, it can be hard to face them head-on. If you are struggling with something, please share your
          story. There will be people willing to pray for you until it becomes a testimony. If you want to pray, please
          check out our <NakedLink to="/hashtag/pray">#pray</NakedLink> page.
        </p>
        <p>
          Everyone has their own story, and their stories might not make sense to you. Just because you don't know their
          struggles, you have no idea how hard it was for them. They still deserve respect in the same way you deserve
          to be respected.
        </p>
        <h2>Our Team</h2>
        <TeamMemberCard>
          <Link to="/david">
            <ProfilePicture src={picDavid} alt="David Sobreira Gouvea" />
          </Link>
          <h3>
            <Link to="/david">David Sobreira Gouvea</Link>
          </h3>
          <h5>co-founder</h5>
          <p>
            David is a husband and father of a sweet little girl. His wife took him to the church when they started
            dating in 2005. At that time, he accepted Jesus in his life, and he started following Him.
          </p>
          <p>
            He is passionate about software development and business startups, and he loves spending time with his
            family and traveling to explore new places.
          </p>
          <p>
            In 2022, David got a call from God to share his testimonies with others, so he decided to build a platform
            where other people could do the same thing. He shared his vision with his friend Andrew, who was very
            interested in the idea, so they started working on that.
          </p>
          <p>David is a software engineer at Benchmark Analytics and attends Snowball Church in Los Angeles, CA.</p>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/david"
            startIcon={<SmallIcon icon={faCloud} />}>
            See Profile
          </Button>
        </TeamMemberCard>
        <TeamMemberCard>
          <Link to="/andrew">
            <ProfilePicture src={picAndrew} alt="Andrew Weeks" />
          </Link>
          <h3>
            <Link to="/andrew">Andrew Weeks</Link>
          </h3>
          <h5>co-founder</h5>
          <p>
            Andrew is a husband and soon-to-be father to a baby boy. He found his faith and love of software engineering
            at about the same time in his teen years.
          </p>
          <p>
            He enjoys building fun apps that help people and was delighted when David approached with the idea that
            would intersect faith and engineering and is excited to see help people connect with each other and God.
          </p>
          <p>
            Andrew is a recovering software engineer and current VP of Services at Enfuse.io, a big data consulting
            company, and attends church-plant Southlands Santa Ana in California.
          </p>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/andrew"
            startIcon={<SmallIcon icon={faCloud} />}>
            See Profile
          </Button>
        </TeamMemberCard>
      </Container>
    </DefaultPage>
  );
}
