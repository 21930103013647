import Cookies from "js-cookie";

export function getSession() {
  try {
    const sessionId = Cookies.get("session");
    if (!sessionId) {
      localStorage.clear();
      return null;
    }
    const sessionJson = localStorage.getItem(`session-${sessionId}`);
    return JSON.parse(atob(sessionJson));
  } catch (e) {
    return null;
  }
}

export function updateSessionUser(user) {
  const session = getSession();
  session.user = user;
  storeCredentials(session);
}

export function storeCredentials(session) {
  Cookies.set("session", session.id, {
    expires: 21600
  });
  localStorage.setItem(`session-${session.id}`, btoa(JSON.stringify(session)));
}

export function removeCredentials() {
  Cookies.remove("session");
  localStorage.clear();
}
