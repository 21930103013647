import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Container, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { removeCredentials, updateSessionUser } from "../api/SecurityApi";
import UserApi from "../api/UserApi";
import DefaultPage from "../component/DefaultPage";
import SignUpForm from "../component/signUp/SignUpForm";
import { ButtonBar, PageTitle } from "../component/UI";
import { displayMessage } from "../event/Actions";
import { useSession } from "../util/Hooks";
import { setTitle } from "../util/Utils";

export default function ProfilePage() {
  const session = useSession();
  const [user, setUser] = useState({});
  const [valid, setValid] = useState(false);
  const [activeTab, setActiveTab] = useState("profile");
  const [revealDanger, setRevealDanger] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!session) {
      navigate("/signin");
      return;
    }

    UserApi.findById(session.user.id).then(currentUser => {
      setUser(currentUser);
      setTitle(`${currentUser.name} Profile`);
    });
  }, [session]);

  const handleUserFormChange = user => {
    setUser(user);
  };

  const handleUserFormValidation = valid => {
    setValid(valid);
  };

  const handleTabChange = (event, tab) => {
    setActiveTab(tab);
  };

  const updateUser = async () => {
    try {
      await UserApi.updateUser({
        id: user.id,
        name: user.name,
        title: user.title,
        birthday: user.birthday
      });

      const sessionUser = await UserApi.findByUsername(user.username);
      updateSessionUser(sessionUser);

      displayMessage({ text: "Profile updated", variant: "success" });
      navigate("/");
    } catch (e) {
      displayMessage({ text: `Error ${e.message}`, variant: "error" });
    }
  };

  const deleteUser = () => {
    setRevealDanger(true);
  };

  const deleteUserNow = async () => {
    await UserApi.deleteUser(user.id);
    removeCredentials();
    displayMessage({ text: "User deleted successfully", variant: "success" });
    navigate("/");
  };

  return (
    <DefaultPage>
      <Container>
        <PageTitle>Profile</PageTitle>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="Profile" value="profile" />
              <Tab label="Account" value="account" />
              <Tab label="Danger" value="danger" />
            </TabList>
          </Box>
          <TabPanel value="profile">
            <SignUpForm
              value={user}
              ignoreFields={["username", "email"]}
              onChange={handleUserFormChange}
              onValidate={handleUserFormValidation}
              readOnly
            />
            <ButtonBar>
              <Button variant="contained" color="primary" disabled={!valid} onClick={updateUser}>
                Update
              </Button>
            </ButtonBar>
          </TabPanel>
          <TabPanel value="account">
            <SignUpForm
              value={user}
              ignoreFields={["name", "title", "birthday"]}
              readOnly
              onChange={handleUserFormChange}
              onValidate={handleUserFormValidation}
            />
          </TabPanel>
          <TabPanel value="danger">
            {!revealDanger && (
              <Button variant="contained" color="error" onClick={deleteUser}>
                Delete my account permanently
              </Button>
            )}
            {revealDanger && (
              <div>
                <h3>Delete account permanently</h3>
                <p>
                  This will immediately delete all your <strong>posts</strong>, <strong>comments</strong>,{" "}
                  <strong>reactions</strong> and your <strong>user</strong>.
                </p>
                <p>Once it's done, you cannot recover your data.</p>
                <ButtonBar>
                  <Button component={Link} to="/" color="success">
                    I want to stay with Jesus With Me
                  </Button>
                  <Button variant="contained" color="error" onClick={deleteUserNow}>
                    Please delete all my data now
                  </Button>
                </ButtonBar>
              </div>
            )}
          </TabPanel>
        </TabContext>
      </Container>
    </DefaultPage>
  );
}
