import { NakedLink, PageTitle } from "../component/UI";
import React, { useEffect } from "react";
import DefaultPage from "../component/DefaultPage";
import { Container } from "@mui/material";
import { setTitle } from "../util/Utils";

export default function HowItWorksPage() {
  useEffect(() => {
    setTitle("How it works?");
  }, []);

  return (
    <DefaultPage>
      <Container>
        <PageTitle>How it works?</PageTitle>
        <h2>How to post</h2>
        <p>Share your story, your journey with Jesus, and how God has changed your life.</p>
        <h3>Testimony</h3>
        <p>
          Please share your testimonies to show others how good it is to have a relationship with Jesus. Your testimony
          can be something simple that would fit in a tweet, or you might want to give extra details and write a
          long-format post. Both are wonderful and welcome.
        </p>
        <p>
          You can tag your post with the hashtag <NakedLink to="/hashtag/testimony">#testimony</NakedLink> so that it
          will be easier for other people to find your story.
        </p>
        <h3>Pray</h3>
        <p>
          Everybody has problems in their lives. If you are struggling with something, you can write a post asking
          people to pray for you. Our team will pray daily for all prayer request posts until your struggle becomes your
          testimony.
        </p>
        <p>
          You can tag your post with the hashtag <NakedLink to="/hashtag/pray">#pray</NakedLink>. We also encourage
          everyone to pray for other people's posts.
        </p>
        <h3>Study</h3>
        <p>
          Are you a pastor? or maybe just passionate about the bible? Please share your knowledge and wisdom with
          others. Everybody needs to get fed by the word of God.
        </p>
        <p>
          You can tag your post with the hashtag <NakedLink to="/hashtag/study">#study</NakedLink>.
        </p>
        <h2>How to react to another post</h2>
        <p>
          You can use the reaction buttons. Currently we have two: <strong>love</strong> and <strong>pray</strong>. You
          can also comment and express your love and respect for other people's posts.
        </p>
      </Container>
    </DefaultPage>
  );
}
