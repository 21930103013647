import { useHandler } from "@aux4/use-handler";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PostApi from "../../../api/PostApi";
import { FORCE_RELOAD_COMMENTS } from "../../../event/PostEvents";
import { useSession } from "../../../util/Hooks";
import PostComment from "./PostComment";

const CommentField = styled.div`
  padding: 0 10px 10px 10px;

  & textarea {
    font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
    font-size: 20px;
  }
`;

export default function PostComments(props) {
  const session = useSession();
  const [text, setText] = useState("");
  const [comments, setComments] = useState([]);

  useEffect(() => {
    PostApi.comments(props.postId).then(comments => setComments(comments));
  }, [props.postId]);

  useHandler(
    (eventType, postId) => {
      if (postId === props.postId) {
        reloadComments().then(() => {});
      }
    },
    [FORCE_RELOAD_COMMENTS]
  );

  const handleChange = e => {
    setText(e.target.value);
  };

  const createComment = async () => {
    await PostApi.comment(props.postId, text);
    setText("");
    await reloadComments();
  };

  const reloadComments = async () => {
    const comments = await PostApi.comments(props.postId);
    setComments(comments);
  };

  return (
    <div>
      {session && (
        <CommentField>
          <TextField
            placeholder="Share your love about that"
            multiline
            fullWidth
            value={text}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <IconButton color="primary" sx={{ flexGrow: 1 }} disabled={text === ""} onClick={createComment}>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </IconButton>
              )
            }}
          />
        </CommentField>
      )}
      {comments.map(comment => (
        <PostComment key={`comment-${comment.id}`} comment={comment} />
      ))}
    </div>
  );
}
