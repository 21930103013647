import Api from "./Api";

const UserApi = {
  createUser: async function (user) {
    const response = await Api.post(`/users`, user);
    return response.data;
  },

  updateUser: async function (user) {
    const response = await Api.put(`/users/${user.id}`, user);
    return response.data;
  },

  deleteUser: async function (userId) {
    const response = await Api.delete(`/users/${userId}`);
    return response.data;
  },

  findById: async function (id) {
    const response = await Api.get(`/users/${id}`);
    return response.data;
  },

  isUsernameInUse: async function (username) {
    const response = await Api.get(`/users?username=${username}`, () => ({}));
    return response.data !== undefined;
  },

  isEmailInUse: async function (email) {
    const response = await Api.get(`/users?email=${email}`, () => ({}));
    return response.data !== undefined;
  },

  findByUsername: async function (username) {
    const response = await Api.get(`/users?username=${username}`);
    return response.data;
  },

  findByEmail: async function (email) {
    const response = await Api.get(`/users?email=${email}`);
    return response.data;
  },

  emailVerification: async function (userId, email, username) {
    await Api.post(`/users/${userId}/requestVerification`, { email, username });
  },

  verifyEmail: async function (userId, verificationCode) {
    const response = await Api.post(`/users/${userId}/verify`, {
      verificationCode: verificationCode
    });
    const session = response.data;
    return {
      id: session.sessionId,
      expires: session.expires
    };
  }
};

export default UserApi;
