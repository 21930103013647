import { useHandler } from "@aux4/use-handler";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { closePostDialog } from "../../event/PostActions";
import { CLOSE_POST_DIALOG, NEW_POST_CREATED, OPEN_POST_DIALOG } from "../../event/PostEvents";
import { ActionButton } from "../UI";
import EditPost from "./EditPost";

const DialogHeader = styled(DialogTitle)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export default function EditPostDialog() {
  const [open, setOpen] = useState(false);
  const [post, setPost] = useState({});

  useHandler(
    (eventType, post) => {
      console.log("[event]", eventType, post);
      setOpen(eventType === OPEN_POST_DIALOG);
      setPost(post || {});
    },
    [OPEN_POST_DIALOG, CLOSE_POST_DIALOG]
  );

  useHandler(() => {
    closePostDialog();
  }, [NEW_POST_CREATED]);

  return (
    <Dialog open={open} onClose={closePostDialog} maxWidth="sm" fullWidth>
      <DialogHeader>
        <ActionButton onClick={closePostDialog}>
          <FontAwesomeIcon icon={faTimes} />
        </ActionButton>
      </DialogHeader>
      <DialogContent>
        <EditPost content={post} />
      </DialogContent>
    </Dialog>
  );
}
