export function isUserPost(session, post) {
  return session && post && session.user.id === post.user.id;
}

export function hasReference(post, referenceType) {
  return post.references && post.references.filter(reference => reference.type === referenceType).length > 0;
}

export function hasHashtag(post, hashtag) {
  return (
    post.references.filter(reference => reference.type === "HASHTAG" && reference.reference === `#${hashtag}`).length >
    0
  );
}

export function filterReferenceByType(references, referenceType) {
  if (!references) {
    return [];
  }
  return references.filter(reference => reference.type === referenceType);
}
