import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./page/HomePage";
import UserPage from "./page/UserPage";
import PostPage from "./page/PostPage";
import SignUpPage from "./page/SignUpPage";
import SignInPage from "./page/SignInPage";
import ProfilePage from "./page/ProfilePage";
import HashtagPage from "./page/HashtagPage";
import AboutUsPage from "./page/AboutUsPage";
import HowItWorksPage from "./page/HowItWorksPage";
import HelpPage from "./page/HelpPage";
import UserMentionsPage from "./page/UserMentionsPage";

export default function PageRouter() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/how-it-works" element={<HowItWorksPage />} />
      <Route path="/need-help" element={<HelpPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/hashtag/:hashtag" element={<HashtagPage />} />
      <Route path="/:username" element={<UserPage />} />
      <Route path="/:username/mentions" element={<UserMentionsPage />} />
      <Route path="/:username/:postId" element={<PostPage />} />
    </Routes>
  );
}
