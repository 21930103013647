import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Skeleton } from "@mui/material";
import PostApi from "../../api/PostApi";
import { useHandler } from "@aux4/use-handler";
import { POST_REACTION } from "../../event/PostEvents";
import PostButtonBar from "./PostButtonBar";

const PostActionsBox = styled.div`
  display: block;
  width: 100%;
`;

const PostStatisticsBox = styled.div`
  padding: 10px 0;
  display: flex;
  width: 100%;
  border-top: solid 1px #ccc;
`;

const ReactionStatistic = styled.span`
  margin-right: 10px;
`;

const REACTIONS = ["love", "pray"];

export default function PostActions(props) {
  const [postReactions, setPostReactions] = useState({ reaction: {}, user: {} });
  const [postReactionStatistics, setPostReactionStatistics] = useState([]);

  const reloadReactions = () => {
    PostApi.reactions(props.content.id).then(reactions => {
      setPostReactions(reactions);
      setPostReactionStatistics(REACTIONS.filter(reaction => reactions.reaction[reaction]));
    });
  };

  useEffect(() => {
    if (!props.content) return;

    reloadReactions();
  }, [props.content]);

  useHandler(
    (eventType, reaction) => {
      if (!props.content) return;

      if (reaction.postId === props.content.id) {
        reloadReactions();
      }
    },
    [POST_REACTION]
  );

  return (
    <PostActionsBox>
      <PostStatisticsBox>
        {props.content && (
          <>
            {postReactionStatistics.map((reaction, index) => (
              <ReactionStatistic key={index}>
                <strong>{postReactions.reaction[reaction]}</strong> {reaction}s
              </ReactionStatistic>
            ))}
            {postReactionStatistics.length === 0 && <span>Be the first to love this</span>}
          </>
        )}
        {!props.content && <Skeleton variant="rectangular" width="100%" height={20} />}
      </PostStatisticsBox>
      <PostButtonBar
        onExpand={props.onExpand}
        content={props.content}
        postReactions={postReactions}
        expanded={props.expanded}
      />
    </PostActionsBox>
  );
}
