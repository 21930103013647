import { faCalendar, faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton } from "@mui/material";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import AuthorAvatar from "../avatar/AuthorAvatar";
import Sidebar from "./Sidebar";
import { NakedLink, SmallIcon } from "../UI";

const AuthorName = styled.h2`
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 800;
`;

const AuthorUsername = styled.h4`
  margin-top: 3px;
  margin-bottom: 0;
  color: rgb(83, 100, 113);
  font-size: 14px;
  font-weight: 400;
`;

const AuthorTitle = styled.h5`
  margin-top: 15px;
  margin-bottom: 5px;
`;

const Joined = styled.h6`
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  color: #444;
`;

export default function AuthorSidebar(props) {
  return (
    <Sidebar
      avatar={<AuthorAvatar large user={props.user} />}
      loadingMenu={!props.user}
      menu={
        props.user && [
          {
            label: "Mentions",
            link: `/${props.user.username}/mentions`,
            icon: <FontAwesomeIcon flip={"horizontal"} icon={faCommentDots} />
          }
        ]
      }>
      <AuthorName>
        {!props.user && <Skeleton variant="rectangular" width="100%" height={24} />}
        {props.user && <NakedLink to={`/${props.user.username}`}>{props.user.name}</NakedLink>}
      </AuthorName>
      <AuthorUsername>
        {!props.user && <Skeleton variant="rectangular" width="100%" height={16} />}
        {props.user && <NakedLink to={`/${props.user.username}`}>@{props.user.username}</NakedLink>}
      </AuthorUsername>
      <AuthorTitle>
        {!props.user && <Skeleton variant="rectangular" width="100%" height={16} />}
        {props.user && props.user.title}
      </AuthorTitle>
      <Joined>
        {!props.user && <Skeleton variant="rectangular" width="100%" height={16} />}
        {props.user && (
          <>
            <SmallIcon icon={faCalendar} /> Joined {moment.unix(props.user.createdAt).format("MMMM YYYY")}
          </>
        )}
      </Joined>
    </Sidebar>
  );
}
