import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEllipsis, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import { ActionButton } from "../UI";
import PostApi from "../../api/PostApi";
import { editPost, postRemoved } from "../../event/PostActions";

export default function PostMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setEditMode = () => {
    editPost(props.postId);
    handleClose();
  };

  const removePost = async () => {
    handleClose();
    await PostApi.remove(props.postId);
    postRemoved(props.postId);
  };

  return (
    <Fragment>
      <Tooltip title="More" placement="top">
        <ActionButton onClick={handleClick}>
          <FontAwesomeIcon icon={faEllipsis} />
        </ActionButton>
      </Tooltip>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={setEditMode}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faEdit} />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={removePost}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faTrash} />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
