import { useEffect, useState } from "react";
import { getSession } from "../api/SecurityApi";
import { LOGIN, LOGOUT } from "../event/SecurityEvents";
import { useHandler } from "@aux4/use-handler";

export function useSession() {
  const [session, setSession] = useState(getSession());

  useEffect(() => {
    const currentSession = getSession();
    setSession(currentSession);
  }, []);

  useHandler(() => {
    const currentSession = getSession();
    setSession(currentSession);
  }, [LOGIN, LOGOUT]);

  return session;
}
