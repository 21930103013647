import { createRef } from "react";
import styled from "styled-components";
import ReactGA from "react-ga4";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import PageRouter from "./PageRouter";
import Messages from "./component/Messages";
import { SnackbarProvider } from "notistack";
import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Redirector from "./component/Redirector";
import { Helmet } from "react-helmet";
import ScrollToTop from "./component/Behaviors";
import EditPostDialog from "./component/post/EditPostDialog";

const theme = createTheme({
  palette: {
    primary: {
      light: "#65d4ff",
      main: "#00A3ED",
      dark: "#0075ba",
      contrastText: "#fff"
    },
    secondary: {
      light: "#4c82c2",
      main: "#005691",
      dark: "#002e63",
      contrastText: "#fff"
    }
  }
});

const DismissButton = styled(Button)`
  && {
    color: #fff;
  }
`;

if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
}

function App() {
  const url = window.location.href;
  const snackbarRef = createRef();

  const onClickDismiss = key => () => {
    snackbarRef.current.closeSnackbar(key);
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          ref={snackbarRef}
          maxSnack={3}
          action={key => <DismissButton onClick={onClickDismiss(key)}>Dismiss</DismissButton>}>
          <Helmet>
            <meta name="og:url" content={url} />
          </Helmet>
          <div className="App">
            <ScrollToTop />
            <PageRouter />
          </div>
          <EditPostDialog />
          <Messages />
          <Redirector />
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
