import React from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Title } from "../UI";
import { useNavigate } from "react-router-dom";

const Frame = styled.div`
  padding: 10px;
  border-radius: 5px;
`;

export default function SignUpBox() {
  const navigate = useNavigate();

  const openSignUpPage = () => {
    navigate(`/signup`);
  };

  return (
    <Frame>
      <Title>New to Jesus with me?</Title>

      <Button
        variant="contained"
        color="primary"
        startIcon={<FontAwesomeIcon icon={faEnvelope} />}
        fullWidth
        onClick={openSignUpPage}>
        Sign up
      </Button>
    </Frame>
  );
}
