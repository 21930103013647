import React, { useState } from "react";
import styled from "styled-components";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from "@mui/material";
import logo from "../static/img/just-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { faCaretDown, faSignIn, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SmallIcon } from "./UI";
import { useSession } from "../util/Hooks";
import { removeCredentials } from "../api/SecurityApi";
import { LOGOUT } from "../event/SecurityEvents";
import { Dispatcher } from "@aux4/dispatcher.js";

const MainBar = styled(AppBar)`
  && {
    box-shadow: none;
    background-color: #61bce5;
  }
`;

const Logo = styled.img`
  padding: 10px 0;
`;

export default function Header() {
  const session = useSession();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    handleClose();
    removeCredentials();
    Dispatcher.dispatch(LOGOUT);
    navigate("/");
  };

  return (
    <MainBar position="static">
      <Toolbar>
        <Typography component="div" style={{ lineHeight: 1 }} sx={{ flexGrow: 1 }}>
          <Link to="/">
            <Logo src={logo} alt="Jesus with me" height={60} />
          </Link>
        </Typography>
        {!session && (
          <Button
            component={Link}
            to="/signin"
            variant="contained"
            color="primary"
            style={{ boxShadow: "none" }}
            startIcon={<SmallIcon icon={faSignIn} />}>
            Log in
          </Button>
        )}
        {session && (
          <div>
            <Button
              style={{ textTransform: "lowercase", color: "#fff" }}
              startIcon={
                <Avatar sx={{ backgroundColor: "#00A3ED" }} style={{ textTransform: "uppercase" }}>
                  {session.user.avatar}
                </Avatar>
              }
              endIcon={<SmallIcon icon={faCaretDown} />}
              onClick={handleMenu}>
              {session.user.username}
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: 250,
                  maxWidth: "100%"
                }
              }}>
              <MenuItem component={Link} to="/profile">
                <ListItemAvatar style={{ color: "#00A3ED" }}>
                  <FontAwesomeIcon icon={faUser} />
                </ListItemAvatar>
                <ListItemText>Profile</ListItemText>
              </MenuItem>
              <Divider />
              <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ m: 2, mb: 1 }}>
                <Button variant="contained" color="secondary" onClick={signOut}>
                  Sign out
                </Button>
              </Box>
            </Menu>
          </div>
        )}
      </Toolbar>
    </MainBar>
  );
}
