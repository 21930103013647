import React from "react";
import Carousel from "react-material-ui-carousel";
import { filterReferenceByType } from "../../../util/PostUtils";
import ExternalLinkPostReference from "../reference/ExternalLinkPostReference";
import YouTubePostReference from "../reference/YouTubePostReference";

export default function ReferenceCarousel(props) {
  return (
    <Carousel autoPlay={false}>
      {filterReferenceByType(props.references, "URL").map((reference, index) => {
        if (
          reference.url.toLowerCase().indexOf("youtube") > -1 ||
          reference.url.toLowerCase().indexOf("youtu.be") > -1
        ) {
          return <YouTubePostReference key={index} {...reference} />;
        }
        return <ExternalLinkPostReference key={index} {...reference} />;
      })}
    </Carousel>
  );
}
