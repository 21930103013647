import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TopicApi from "../../api/TopicApi";
import HahstagAvatar from "../avatar/HashtagAvatar";
import Sidebar from "./Sidebar";
import { ContentCenter } from "../UI";

const Hashtag = styled.h1`
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 800;
`;

const HashtagDescription = styled.div`
  margin-top: 8px;
  text-align: center;
  color: #666;
`;

export default function HashtagSidebar(props) {
  const [hashtag, setHashtag] = useState({ name: props.hashtag });
  const [loadingTopics, setLoadingTopics] = useState(true);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    setLoadingTopics(true);

    TopicApi.list().then(topics => {
      setTopics(topics);
      setLoadingTopics(false);
    });
  }, []);

  useEffect(() => {
    TopicApi.get(props.hashtag).then(hashtag => setHashtag(hashtag));
  }, [props.hashtag]);

  return (
    <Sidebar
      header={props.header}
      avatar={<HahstagAvatar large />}
      loadingMenu={loadingTopics}
      menu={
        topics &&
        topics.map(topic => ({
          label: `#${topic.name}`,
          link: `/hashtag/${topic.name}`,
          selected: topic.name === props.hashtag
        }))
      }>
      <ContentCenter>
        <Hashtag>#{hashtag.name}</Hashtag>
      </ContentCenter>
      <ContentCenter>
        <HashtagDescription>{hashtag.description}</HashtagDescription>
      </ContentCenter>
    </Sidebar>
  );
}
