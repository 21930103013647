import { Form, Title } from "../UI";
import TextVerification from "../TextVerification";
import React from "react";

export default function EmailVerification(props) {
  const handleChange = text => {
    props.onChange(text);
    props.onValidate(text.length === 6);
  };

  return (
    <Form>
      <Title>Verify your email</Title>
      <p>
        To begin using <strong>Jesus with me</strong> please verify your email. Look for an email from{" "}
        <strong>help@jesuswith.me</strong>. It may have gone to SPAM.
      </p>
      <p>
        Instead of passwords, Jesus with me will send you an email for each device you would like to login on. Your
        session will last for 7 days or until you logout.
      </p>
      <p>
        Please verify your email <strong>{props.email}</strong> and enter the verification code:
      </p>
      <TextVerification onChange={handleChange} chars={6} />
    </Form>
  );
}
