import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ActionButton } from "../../UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faPray } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import PostApi from "../../../api/PostApi";
import { postReaction } from "../../../event/PostActions";

const ReactionActionButton = styled(ActionButton)`
  color: ${props => (props.checked ? props.buttoncolorchecked : props.buttoncolor)} !important;

  &:hover {
    color: ${props => props.buttoncolorchecked} !important;
  }
`;

export function LoveReactButton(props) {
  return (
    <ReactionButton
      title="Love"
      type="love"
      color="#ffcccb"
      checkedColor="#ff0000"
      tooltip="people love this"
      {...props}>
      <FontAwesomeIcon icon={faHeart} />
    </ReactionButton>
  );
}

export function PrayReactButton(props) {
  return (
    <ReactionButton
      title="Pray"
      type="pray"
      color="#b4d3b2"
      checkedColor="#008800"
      tooltip="people are praying for this"
      {...props}>
      <FontAwesomeIcon icon={faPray} />
    </ReactionButton>
  );
}

function ReactionButton(props) {
  const [userReaction, setUserReaction] = useState(props.userReaction || {});

  useEffect(() => {
    setUserReaction(props.userReaction);
  }, [props.userReaction]);

  return (
    <Tooltip title={props.title} placement="bottom">
      <ReactionActionButton
        checked={userReaction[props.type] === true}
        buttoncolor={props.color}
        buttoncolorchecked={props.checkedColor}
        onClick={() => toggleReaction(props.postId, props.type, userReaction[props.type] === true)}>
        {props.children}
      </ReactionActionButton>
    </Tooltip>
  );
}

async function toggleReaction(postId, reaction, checked) {
  if (checked) {
    await PostApi.unReact(postId, reaction);
  } else {
    await PostApi.react(postId, reaction);
  }
  postReaction(postId, reaction);
}
