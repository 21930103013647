import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket, faArrowUpRightFromSquare, faLink } from "@fortawesome/free-solid-svg-icons";
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import { ActionButton } from "../../UI";
import { faFacebook, faLinkedin, faPinterest, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { objectToQueryParams } from "../../../util/Utils";
import { Link } from "react-router-dom";

export default function ShareButton(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(props.url);
    handleClose();
  };

  const shareOnTwitter = () => {
    share("https://twitter.com/share", { url: props.url, text: props.text });
  };

  const shareOnFacebook = () => {
    share("https://www.facebook.com/sharer/sharer.php", {
      u: props.url,
      quote: props.text
    });
  };

  const shareOnPinterest = () => {
    share("https://pinterest.com/pin/create/button", {
      url: props.url,
      description: props.text
    });
  };

  const shareOnLinkedIn = () => {
    share("https://linkedin.com/shareArticle", {
      url: props.url,
      summary: props.text,
      mini: "true"
    });
  };

  const share = (url, params) => {
    handleClose();
    window.open(url + objectToQueryParams(params), "Share Post", "width=650,height=700");
  };

  return (
    <Fragment>
      <Tooltip title="Share" placement="bottom">
        <ActionButton onClick={handleClick}>
          <FontAwesomeIcon icon={faArrowUpFromBracket} />
        </ActionButton>
      </Tooltip>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem component={Link} to={`/posts/${props.postId}`} target="_blank" rel="noopener noreferrer">
          <ListItemIcon>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </ListItemIcon>
          <ListItemText>Open post</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={copyToClipboard}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faLink} />
          </ListItemIcon>
          <ListItemText>Copy link</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={shareOnTwitter}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faTwitter} />
          </ListItemIcon>
          <ListItemText>Share on Twitter</ListItemText>
        </MenuItem>
        <MenuItem onClick={shareOnFacebook}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faFacebook} />
          </ListItemIcon>
          <ListItemText>Share on Facebook</ListItemText>
        </MenuItem>
        <MenuItem onClick={shareOnPinterest}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faPinterest} />
          </ListItemIcon>
          <ListItemText>Share on Pinterest</ListItemText>
        </MenuItem>
        <MenuItem onClick={shareOnLinkedIn}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faLinkedin} />
          </ListItemIcon>
          <ListItemText>Share on LinkedIn</ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
