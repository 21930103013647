import React from "react";
import styled from "styled-components";
import { LoveReactButton, PrayReactButton } from "./button/ReactionButtons";
import ShareButton from "./button/ShareButton";
import { Skeleton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ActionButton } from "../UI";

const PostButtonBox = styled.div`
  padding-top: 5px;
  display: flex;
  width: 100%;
  border-top: solid 1px #ccc;
`;

const ExpandMore = styled(ActionButton)`
  && {
    margin: 0 0 0 auto;
    transform: ${props => (!props.expand ? "rotate(0deg)" : "rotate(180deg)")};
    font-size: 1.2rem;
    flex: 0 0 auto;
  }
`;

export default function PostButtonBar(props) {
  const handleExpand = async e => {
    e.preventDefault();
    props.onExpand(!props.expanded);
  };

  return (
    <PostButtonBox>
      {props.content && (
        <>
          <LoveReactButton postId={props.content.id} userReaction={props.postReactions.user} />
          <PrayReactButton postId={props.content.id} userReaction={props.postReactions.user} />
          <ShareButton
            postId={props.content.id}
            url={`${process.env.REACT_APP_URL}/${props.content.user.username}/${props.content.id}`}
            text={props.content.title}
          />

          {props.content.short !== props.content.text && (
            <Tooltip title={props.expanded ? "Read less" : "Read more"} placement="top">
              <ExpandMore
                expand={props.expanded}
                onClick={handleExpand}
                aria-expanded={props.expanded}
                aria-label="show more">
                <FontAwesomeIcon icon={faChevronDown} />
              </ExpandMore>
            </Tooltip>
          )}
        </>
      )}
      {!props.content && <Skeleton variant="rectangular" width="100%" height={40} />}
    </PostButtonBox>
  );
}
