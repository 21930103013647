import axios from "axios";
import { getSession } from "./SecurityApi";
import { errorHandler } from "../util/ErrorHandler";

const API = process.env.REACT_APP_API;

const DEFAULT_ERROR_HANDLER = e => {
  throw errorHandler(e);
};

const Api = {
  get: async function (uri, customErrorHandler = DEFAULT_ERROR_HANDLER) {
    return await axios.get(`${API}${uri}`, getConfig()).catch(customErrorHandler);
  },

  post: async function (uri, data, customErrorHandler = DEFAULT_ERROR_HANDLER) {
    return await axios.post(`${API}${uri}`, data, getConfig()).catch(customErrorHandler);
  },

  put: async function (uri, data, customErrorHandler = DEFAULT_ERROR_HANDLER) {
    return await axios.put(`${API}${uri}`, data, getConfig()).catch(customErrorHandler);
  },

  delete: async function (uri, customErrorHandler = DEFAULT_ERROR_HANDLER) {
    return await axios.delete(`${API}${uri}`, getConfig()).catch(customErrorHandler);
  }
};

function getConfig() {
  const session = getSession();
  if (!session) {
    return {};
  }

  return {
    headers: {
      "Session-Id": session.id
    }
  };
}

export default Api;
