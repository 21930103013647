import React from "react";
import { Helmet } from "react-helmet";

export function Metadata(props) {
  return (
    <Helmet>
      <meta name="description" content={props.description} />
      <meta property="twitter.title" content={props.title} />
      <meta name="twitter.description" content={props.description} />
      <meta property="og:title" content={props.title} />
      <meta name="og:description" content={props.description} />
    </Helmet>
  );
}
