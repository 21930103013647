import { Avatar, Skeleton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const InvisibleLink = styled(Link)`
  text-decoration: none;

  &&:hover {
    text-decoration: none;
  }
`;

export default function AuthorAvatar(props) {
  if (!props.user) {
    return <Skeleton variant="circular" width={props.large ? 90 : 40} height={props.large ? 90 : 40} />;
  }

  if (props.large) {
    return (
      <InvisibleLink to={`/${props.user.username}`}>
        <Avatar sx={{ backgroundColor: "#00A3ED", width: 90, height: 90, fontSize: 40 }}>{props.user.avatar}</Avatar>
      </InvisibleLink>
    );
  }
  return (
    <InvisibleLink to={`/${props.user.username}`}>
      <Avatar sx={{ backgroundColor: "#00A3ED" }}>{props.user.avatar}</Avatar>
    </InvisibleLink>
  );
}
