import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { textTransform } from "../text/TextTransformer";

export function MarkdownLink({ href, ...otherProps }) {
  if (href.indexOf(process.env.REACT_APP_URL) === -1) {
    return <a href={href} target="_blank" rel="noreferrer" {...otherProps} />;
  }
  return <a href={href} {...otherProps} />;
}

export function MarkdownCode(props) {
  return <code className={props.className}>{props.children}</code>;
}

export function Markdown(props) {
  const [text, setText] = useState("");

  useEffect(() => {
    textTransform(props.text, props.post).then(content => setText(content));
  }, [props.text, props.post]);

  return (
    <ReactMarkdown
      components={{ a: MarkdownLink, code: MarkdownCode, pre: props1 => <div>{props1.children}</div> }}
      remarkPlugins={[remarkGfm]}
      children={text}
    />
  );
}
