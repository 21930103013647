import React from "react";
import styled from "styled-components";
import { CardContent, CardHeader, CardMedia } from "@mui/material";
import { PostReference } from "../../UI";

const CardLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }
`;

const ReferenceCardContent = styled(CardContent)`
  padding-top: 0 !important;
  line-height: 1.3rem;
  font-size: 16px;
  color: #666;
`;

export default function ExternalLinkPostReference(props) {
  return (
    <CardLink href={props.url} target="_blank">
      <PostReference>
        <CardMedia component="img" style={{ maxHeight: 350 }} image={props.image} />
        <CardHeader title={props.title} subheader={props.url} />
        <ReferenceCardContent>{props.description}</ReferenceCardContent>
      </PostReference>
    </CardLink>
  );
}
