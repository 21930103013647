import React, { Fragment, useState } from "react";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import styled from "styled-components";
import { AuthorName, AuthorUsername, MarkdownContent, PostTime, SmallIcon } from "../../UI";
import { useSession } from "../../../util/Hooks";
import PostApi from "../../../api/PostApi";
import { forceReloadComments } from "../../../event/PostActions";
import { faCaretDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Markdown } from "../../Markdown";
import moment from "moment";
import { Link } from "react-router-dom";
import AuthorAvatar from "../../avatar/AuthorAvatar";

const Comment = styled.div`
  position: relative;
  padding: 10px 0;
  background-color: rgba(0, 0, 0, 0.03);
`;

const CommentAvatar = styled.div`
  position: absolute;
  top: 12px;
  left: 16px;

  && a {
    text-decoration: none;
    color: inherit;
  }
`;

const CommentContent = styled.div`
  padding: 0 10px 0 72px;
`;

const CommentHeader = styled.div`
  && a {
    text-decoration: none;
    color: inherit;
  }
`;

const CommentText = styled.div`
  font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 18px;
`;

const CommentMarkdownContent = styled(MarkdownContent)`
  && {
    padding: 0 !important;
    font-size: 18px;
  }

  && p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  && p:not(:first-child) {
    margin-block-start: 5px;
  }

  && p:not(:last-child) {
    margin-block-end: 5px;
  }
`;

const CommentAction = styled(IconButton)`
  && {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
  }
`;

export default function PostComment(props) {
  const session = useSession();

  return (
    <Comment key={`comment-${props.comment.id}`}>
      {session && session.user.id === props.comment.user.id && (
        <PostCommentMenu postId={props.postId} commentId={props.comment.id} />
      )}
      <CommentAvatar>
        <AuthorAvatar user={props.comment.user} />
      </CommentAvatar>
      <CommentContent>
        <CommentHeader>
          <AuthorName>
            <Link to={`/${props.comment.user.username}`}>{props.comment.user.name}</Link>
          </AuthorName>
          <AuthorUsername>
            <Link to={`/${props.comment.user.username}`}>@{props.comment.user.username}</Link>
          </AuthorUsername>
          {props.comment.createdAt && (
            <>
              {" · "}
              <Tooltip title={moment.unix(props.comment.createdAt).format("hh:mm A · MMM DD, YYYY")}>
                <PostTime>{moment.unix(props.comment.createdAt).fromNow()}</PostTime>
              </Tooltip>
            </>
          )}
        </CommentHeader>
        <CommentText>
          <CommentMarkdownContent>
            <Markdown text={props.comment.text} post={props.comment} />
          </CommentMarkdownContent>
        </CommentText>
      </CommentContent>
    </Comment>
  );
}

function PostCommentMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteComment = async () => {
    await PostApi.deleteComment(props.postId, props.commentId);
    handleClose();
    forceReloadComments(props.postId);
  };

  return (
    <Fragment>
      <CommentAction onClick={handleClick}>
        <SmallIcon icon={faCaretDown} />
      </CommentAction>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={deleteComment}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faTrash} />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
