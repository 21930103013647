import Api from "./Api";

const TopicApi = {
  list: async function () {
    const response = await Api.get("/topics");
    return response.data.topics;
  },

  get: async function (hashtag) {
    const response = await Api.get("/topics");
    return response.data.topics.find(item => item.name === hashtag);
  }
};

export default TopicApi;
