import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CardContent, CardHeader, CardMedia } from "@mui/material";
import YouTubeVideo from "../../YouTubeVideo";
import { PostReference } from "../../UI";

const CardLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }
`;

const ReferenceCardContent = styled(CardContent)`
  padding-top: 0 !important;
  line-height: 1.3rem;
  font-size: 16px;
  color: #666;
`;

const VIDEO_ID_REGEX = /(v=|youtu\.be\/)(?<videoId>[^&?]+)/;

export default function YouTubePostReference(props) {
  const [videoId, setVideoId] = useState(extractVideoId(props.url));

  useEffect(() => {
    setVideoId(extractVideoId(props.url));
  }, [props.url]);

  return (
    <PostReference>
      <CardMedia>
        <YouTubeVideo videoId={videoId} />
      </CardMedia>
      <CardLink href={props.url} target="_blank">
        <CardHeader title={props.title} subheader={props.url} />
        <ReferenceCardContent>{props.description}</ReferenceCardContent>
      </CardLink>
    </PostReference>
  );
}

function extractVideoId(url) {
  const match = VIDEO_ID_REGEX.exec(url);
  return match.groups.videoId;
}
