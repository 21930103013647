import React, { useEffect, useState } from "react";
import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Form, Title } from "../UI";

export default function Terms(props) {
  const [terms, setTerms] = useState(
    props.value || [
      { checked: false, text: "❤️ I love Jesus️" },
      { checked: false, text: "🤗 I will respect others on this platform" },
      { checked: false, text: "🤬 I will never share offensive content on this platform" }
    ]
  );

  useEffect(() => {
    if (props.value) {
      setTerms(props.value);
    }
  }, [props.value]);

  const handleChange = index => {
    const newTerms = [...terms];
    newTerms[index].checked = !newTerms[index].checked;
    setTerms(newTerms);

    props.onChange(newTerms);
    props.onValidate(newTerms.map(term => term.checked).every(checked => checked === true));
  };

  return (
    <Form>
      <Title>Our Terms</Title>
      <p>
        You must agree to all of <strong>Jesus With Me</strong>'s terms before you join:
      </p>

      <List>
        {terms.map((term, index) => (
          <ListItem key={term.text} disablePadding>
            <ListItemButton disableGutters onClick={() => handleChange(index)}>
              <ListItemIcon>
                <Checkbox checked={term.checked} />
              </ListItemIcon>
              <ListItemText>{term.text}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Form>
  );
}
